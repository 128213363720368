import styled from "styled-components";
import {CSSProperties} from "react";

export const SectionWrapper = styled.div<{ background?: string }>`
  background: ${props => props.background ? props.background : "transparent"};
`;

export const SectionInner = styled.div<{ css?: CSSProperties, size?: "large" | "medium" | "small" }>`
  max-width: 1240px;
  margin: 0 auto;
  padding: ${props => {
    switch (props.size) {
      case "large":
        return "128px 0";
      case "medium":
        return "80px 0";
      case "small":
        return "48px 0"
      default:
        return "0"
    }
  }};

  @media (max-width: 1400px) {
    max-width: 1140px;
  }

  @media (max-width: 1200px) {
    max-width: 960px;
  }

  @media (max-width: 992px) {
    max-width: 720px;
  }

  @media (max-width: 768px) {
    max-width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }

}
`