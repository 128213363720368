import styled from "styled-components";

export const List = styled.div`
  color: ${({theme}) => theme.colors.green};
  
  ul {
    margin-top: 8px;
    
    li {
      margin-bottom: 8px;
      
      &:last-child {
        margin-bottom: 0;
      }
    }
    a {
      color: currentColor;
      font-size: 16px;
      margin-top: 8px;
      font-weight: 300;
    }
  }
`