import * as React from "react";
import {LinkProps} from "../../../types/Link";
import {Link} from "gatsby";
import * as Styled from "./List.styled";

interface ListProps {
    title: string,
    links: LinkProps[]
}

const List: React.FC<ListProps> = ({title, links}) => {
    return (
        <Styled.List>
            <h4>{title}</h4>
            <ul>
                {links.map((link, index) => <li key={`${title}-${index}`}>
                    <Link to={link.to}>{link.content}</Link>
                </li>)}
            </ul>
        </Styled.List>
    )

};

export default List;