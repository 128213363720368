import * as React from "react";
import * as Styled from "./Header.styled";
import Logo from "../../../icons/logo.inline.svg";
import Menu from "../../../icons/menu.inline.svg";
import LogoColor from "../../../icons/logo-color.inline.svg";
import Close from "../../../icons/close.inline.svg";
import {LinkProps} from "../../../types/Link";
import {useEffect, useState} from "react";
import {scrollTo} from "../../../utils/utils";

const links: LinkProps[] = [
    {
        to: '#products',
        content: "Produkty",
    },
    {
        to: '#ourstory',
        content: "Nasza historia",
    },
    {
        to: '#contact',
        content: "Kontakt",
    }
];



const Header: React.FC<{secondary?: boolean}> = ({secondary}) => {
    const [isVisible, setVisible] = useState(false);
    const [sticky, setSticky] = useState(false);

    const onWindowResize = () => {
        setVisible(false);
    }

    const handleScroll=() => {
        if (window.scrollY > 100 && window.innerWidth < 992){
            setSticky(true);
        } else{
            setSticky(false);
        }
    }


    useEffect(() => {
        window.addEventListener("resize", onWindowResize);
        window.addEventListener('scroll',handleScroll)
        return () => {
            window.removeEventListener("resize", onWindowResize);
            window.removeEventListener('scroll',handleScroll);

        };
    }, []);

    const handleClick = (to: string) => {
        setVisible(false);
        scrollTo(to);
    }

    return (
        <Styled.Header isSticky={sticky} secondary={secondary}>
            {sticky || secondary ? <LogoColor/> : <Logo/>}
            <Styled.HeaderMenuToggler onClick={() => setVisible(true)} isSticky={sticky}>
                <Menu />
            </Styled.HeaderMenuToggler>

            <Styled.HeaderMenu show={isVisible} secondary={secondary}>
                <Styled.HeaderMobileBar>
                    <LogoColor />
                    <div onClick={() => setVisible(false)}><Close/></div>

                </Styled.HeaderMobileBar>
                <ul>
                    {links.map(({to, content}, index) => {
                        return <li key={`menu-element-${index}`}>
                            <button onClick={() => handleClick(to)}>{content}</button>
                        </li>
                    })}
                </ul>

            </Styled.HeaderMenu>
        </Styled.Header>
    )

}

export default Header;