import * as React from "react";
import * as Styled from "./Section.styled";

export type SectionBackgroundType = ""

export interface SectionProps {
    background?: string,
    id?: string,
    size?: "large" | "medium" | "small"
}

const Section: React.FC<SectionProps> = ({background, id, size , children}) => {
    return (
        <Styled.SectionWrapper background={background} id={id}>
            <Styled.SectionInner size={size}>
                {children}
            </Styled.SectionInner>
        </Styled.SectionWrapper>
    )
}

export default Section;