import styled, {css} from "styled-components";

export const HeaderMenuToggler = styled.div<{isSticky?: boolean}>`
  display: none;
  
  @media (max-width: 992px) {
    display: block;
    ${props => props.isSticky && css`
      svg {
        path {
          stroke: ${({theme}) => theme.colors.green};
        }
      }
    `}
  }
`

export const Header = styled.header<{isSticky?: boolean, secondary?: boolean}>`
  position: ${props => props.secondary ? "relative" : "absolute"};
  z-index: 1;
  padding: 0 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  
  @media (max-width: 992px) {
    padding: 0 16px;
    
    ${props => props.isSticky && css`
      position: fixed;
      background: white;
      box-shadow: 0px 4px 40px rgba(19, 71, 38, 0.12));
    `}
  }
`

export const HeaderMobileBar = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  padding: 16px;
  align-items: center;
  justify-content: space-between;
  
  @media (max-width: 992px) {
    display: flex;
  }
`

export const HeaderMenu = styled.nav<{show: boolean, secondary?: boolean}>`
  display: flex;
  transition: left 0.3s;
  
  ul {
    display: flex;
  }
  
  li {
    padding: 0 28px;
  }
  button {
    text-transform: uppercase;
    text-decoration: none;
    color: ${props => props.secondary ? props.theme.colors.green : "white"};
    line-height: 19px;
    font-size: 14px;
    font-weight: 700;
    background: transparent;
    border: none;
    
    &:hover {
      cursor: pointer;
    }
  }

  @media (max-width: 991px) {
    position: absolute;
    background: white;
    left: ${props => props.show ? 0 : "-9999px"};
    top: 0;
    width: 100%;
    height: 100vh;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    z-index: 1;
    
    ul {
      flex-direction: column;
    }
    li {
      margin: 28px 0;
      display: flex;
      justify-content: flex-end;
      button {
        text-align: right;
        color: ${props => props.theme.colors.green}
      }
    }
  }
`