import styled from "styled-components";
import {List} from "../../atoms/List/List.styled";

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 48px 0;
  p {
    color: ${props => props.theme.colors.green};
    ${props => ({...props.theme.sizes.p})}
  }
  
  @media (max-width: 992px) {
    flex-direction: column;
  }

  ${List} {

    @media (max-width: 992px) {
      margin-bottom: 32px;

      &:last-child {
        margin-bottom: 0;
      }
    }

  }
`;

export const FooterLogo = styled.div`
  margin-bottom: 32px;
  p {
    
  }
`;

export const FooterCopyright = styled.div`
  padding: 24px 0;
  
  p {
    color: ${props => props.theme.colors.green};
    ${(props) => ({...props.theme.sizes.pSmall})};
    a {
      color: currentColor;
      text-decoration: none;
    }
  }
`