import {createGlobalStyle} from "styled-components";

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: gira-sans, sans-serif;
  }
  
  ul {
    list-style: none;
    li {
      a {
        text-decoration: none;
      }
    }
  }
  
  h1 {
    font-size: 64px;
    font-weight: 300;
    line-height: 84px;
  }
  
  h2 {
    font-weight: 300;
    font-size: 48px;
    line-height: 63px;
  }
  
  h4 {
    font-size: 24px;
    line-height: 37px;
    font-weight: 300;
  }
  
  h5 {
    font-weight: 300;
    line-height: 31px;
    font-size: 20px;
  }
  
  h6 {
    font-size: 16px;
    line-height: 27px;
    font-weight: 300;
  }
`