import * as React from "react";
import Section from "../../templates/Section/Section";
import theme from "../../../styles/theme";
import List from "../../atoms/List/List";
import {Link} from "gatsby";
import * as Styled from "./Footer.styled";
import Logo from "../../../icons/logo-color.inline.svg";

const Footer: React.FC = () => {
    return <footer>
        <Section background={theme.colors.greyLight}>
            <Styled.Footer>
                <Styled.FooterLogo>
                    <Logo />
                    <p>Wojciecha Cybulskiego 1 / 1B, 50-206 Wrocław</p>
                </Styled.FooterLogo>
                <List
                    title={"Nasza historia"}
                    links={[
                        {
                            to: "/",
                            content: "O nas"
                        },
                        {
                            to: "/",
                            content: "Fumum w liczbach"
                        }
                    ]}
                />
                <List
                    title={"Produkty"}
                    links={[
                        {
                            to: "/",
                            content: "Wkrótce"
                        }
                    ]}
                />          
                <List
                    title={"Kontakt"}
                    links={[
                        {    
                            content: "biuro@fumum.pl"
                        },
                        {
                            content: "+48 71 307 24 26"
                        }
                    ]}
                />
            </Styled.Footer>
            <Styled.FooterCopyright>
                <p>Fumum {new Date().getFullYear()}, <Link to={"#"}>Polityka prywatnosci</Link></p>
            </Styled.FooterCopyright>
        </Section>
    </footer>
}

export default Footer;