import * as React from "react"
import {GlobalStyle} from "../../../styles/global";
import SEO from "../../other/SEO/SEO";
import Header from "../../organism/Header/Header";
import Footer from "../../organism/Footer/Footer";

interface LayoutProps {
    title: string,
    secondary?: boolean
}

const Layout: React.FC<LayoutProps> = ({title, secondary, children }) => {
    return (
        <>
            <SEO title={title} />
            <GlobalStyle/>
            <Header secondary={secondary} />
            <main>{children}</main>
            <Footer />
        </>
    )
}
export default Layout
